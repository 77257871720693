import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Tag } from '../../../types/tag'
import { ScrollAndShow } from '../../../components/scroll-and-show'
import { Layout } from '../../../components/layout'
import { ArticleCard } from '../../../components/article-card'
import { useTags } from '../../../hooks/useTags'
import { useStories } from '../../../hooks/useStories'
import { useEventReports } from '../../../hooks/useEventReports'
import { SEO } from '../../../components/seo'

const Page = ({ params }: any) => {
	const { tags } = useTags()
	const { stories } = useStories()
	const { eventReports } = useEventReports()

	const [tag, setTag] = useState<Tag | undefined>(undefined)
	const [articles, setArticles] = useState([])

	useEffect(() => {
		const tag = tags.find(tag => tag.title === params._tag)
		setTag(tag)

		const newStories = stories.filter(story => story.tags.find(tag => tag.title === params._tag))
		const newEventReports = eventReports.filter(eventReport => eventReport.tags.find(tag => tag.title === params._tag))
		setArticles([...newStories, ...newEventReports])
	}, [tags, stories, eventReports])

	return (
		<Layout>
			<SEO
				title={`${params._tag} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={''}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">{params._tag} | RUBIA ルビア / DJ sarasa</h1>
			<div className="article">
				<div className="text-center">
					<ScrollAndShow>
						<div className="tag font-lato">TAG</div>
					</ScrollAndShow>
					<ScrollAndShow>
						<div className="title-text font-yu">#{params._tag}</div>
					</ScrollAndShow>
				</div>
				<ScrollAndShow>
					<div className="tag-description">{tag && tag.description}</div>
				</ScrollAndShow>
				<div className="container">
					{articles.map((article, index) => (
						<ScrollAndShow key={index} delay={100 * index}>
							<Link to={`${article.vol ? 'stories' : 'event_reports'}/${article.title}`}>
								<ArticleCard article={article} />
							</Link>
						</ScrollAndShow>
					))}
				</div>
				<ScrollAndShow>
					<div className="categories-link">
						<Link className="font-yu" to="/categories">カテゴリー一覧へ</Link>
					</div>
				</ScrollAndShow>
			</div>
		</Layout>
	)
}

export default Page

